//@ts-nocheck
import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef
} from 'react';
import { useRouter } from 'next/router';
import { Button, Checkbox, Form, Input, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useFm } from '@better-bit-fe/base-hooks';
import { useUserInfo } from '@better-bit-fe/base-provider';
import { useCaptcha } from '~/hooks/useCaptcha';
import { emailValidate } from '~/utils/validation';
import useCountDown from '~/hooks/useCountDown';
import { postEmailCodeSend, postEmailLogin, postProfileUpdate } from '~/api';
import { EmailCodeType, STORAGE_ADDRESS, ISNEWUSER } from '~/constants';
import { useParticle } from '~/hooks/useParticle';
import Style from './index.module.less';

type FieldType = {
  username?: string;
  password?: string;
};
interface IEmail {
  referralCode?: string;
  walletDetail: {
    visible: boolean;
    address: string;
  };
  changeWalletDetail: ({ visible: boolean, address: string }) => void;
}

function Email(props: IEmail, ref: any) {
  const { walletDetail, referralCode, changeWalletDetail } = props;
  const [emailInp, setemailInp] = useState('');
  const [captchaInfo, setcaptchaInfo] = useState({});
  const [emailCheckPass, setemailCheckPass] = useState(false);
  // const [particleAddress, setparticleAddress] = useState('');
  const [loading, setloading] = useState<boolean>(false);
  const { updateUserInfo, userInfo } = useUserInfo();
  const { countdown, isResendDisabled, resendTimeInterver, clearTimeInterver } =
    useCountDown();
  const formRef = useRef();
  const t = useFm();
  const { locale } = useRouter();
  const captcha = useCaptcha();
  const { connect, logout: particleLogout, getAddress } = useParticle();

  const onFinish = async (values: any) => {
    console.log('Success:', values);
    setloading(true);
    const params = referralCode
      ? {
          referral_code: referralCode,
          email: values.email,
          email_code: values.password
        }
      : { email: values.email, email_code: values.password };
    try {
      const loginData = await postEmailLogin({ ...params });
      const { is_new_user, particle_token } = loginData;
      localStorage.setItem(ISNEWUSER, is_new_user);
      localStorage.setItem(
        STORAGE_ADDRESS,
        JSON.stringify({
          address: '',
          walletName: 'email',
          particle_token
        })
      );
      const newUserInfo = await updateUserInfo();
      const isWalletLoadingOpen =
        is_new_user ||
        (newUserInfo?.address && newUserInfo.wallet_type === 2) ||
        !newUserInfo?.address;
      const isOldParticleUser =
        newUserInfo?.address && newUserInfo.wallet_type === 3;
      if (isWalletLoadingOpen) {
        changeWalletDetail({
          visible: true,
          address: walletDetail.address
        });
        setloading(false);
      }
      message.success(t('loginTips'));
      if (isOldParticleUser) {
        // old users to async connect wallet for optimization
        setTimeout(() => {
          try {
            connect({ jwt: particle_token });
          } catch (error) {
            console.log(error, 'connect error');
          }
        }, 0);
        changeWalletDetail({
          visible: isWalletLoadingOpen,
          address: newUserInfo?.address
        });
      } else {
        try {
          await connect({ jwt: particle_token });
          const _particleAddress = await getAddress();
          changeWalletDetail({
            visible: isWalletLoadingOpen,
            address: _particleAddress
          });
        } catch (error) {
          console.log(error, 'connect error');
        }
      }

      setloading(false);
      // open particle address loading modal
    } catch (error) {
      console.log(error, 'error');
      setloading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  async function sendCode(_captchaInfo) {
    if (emailInp) {
      await postEmailCodeSend({
        captcha_type: 'geetest',
        geetest_challenge: _captchaInfo?.geetest_challenge,
        geetest_validate: _captchaInfo?.geetest_validate,
        geetest_seccode: _captchaInfo?.geetest_seccode,
        email_type: EmailCodeType.login_code,
        email: emailInp,
        lang: locale
      });
    }
  }

  async function showCaptcha() {
    if (isResendDisabled || !emailCheckPass) return;
    if (!emailInp) return;
    try {
      const _captchaInfo = await captcha.showCaptcha();
      setcaptchaInfo(_captchaInfo);
      resendTimeInterver();
      await sendCode(_captchaInfo);
    } catch (e) {
      setcaptchaInfo({});
      throw e;
    }
  }

  function checkEmail() {
    const formData = formRef.current.getFieldsValue();
    if (!emailValidate(formData.email)) {
      setemailCheckPass(false);
      return Promise.reject(t('emailInpMsg'));
    }
    setemailCheckPass(true);
    return Promise.resolve();
  }

  // useEffect(() => {
  //   console.log(userInfo?.address, particleAddress, 999);
  //   if (userInfo && particleAddress) {
  //     if (userInfo?.address !== particleAddress) {
  //       postProfileUpdate({ address: particleAddress });
  //     }
  //   }
  // }, [particleAddress, userInfo]);

  useEffect(() => {
    return () => {
      clearTimeInterver();
    };
  }, []);

  return (
    <div className={Style.email}>
      <div className="title">{t('email-title')}</div>
      <Form
        name="email"
        ref={formRef}
        style={{ maxWidth: 600, margin: '32px 0' }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item<FieldType>
          label=""
          name="email"
          rules={[{ required: true, message: ' ' }, { validator: checkEmail }]}
        >
          <Input
            className={Style.userInp}
            placeholder={t('email-address')}
            value={emailInp}
            onChange={(event) => {
              setemailInp(event.target.value);
            }}
          />
        </Form.Item>

        <Form.Item<FieldType>
          label=""
          name="password"
          rules={[{ required: true, message: t('emailCodeInpMsg') }]}
        >
          <Input
            className={Style.userInp}
            placeholder={t('verification-code')}
            suffix={
              <div
                className={`${Style.sentCodeBtn} ${
                  !emailCheckPass ? Style.sentDisable : ''
                }`}
                onClick={showCaptcha}
              >
                {isResendDisabled ? countdown + ' s' : t('sendCode-btn')}
              </div>
            }
          />
        </Form.Item>

        <Form.Item style={{ marginTop: '35px' }}>
          <Button
            type="primary"
            htmlType="submit"
            // disabled={!isResendDisabled}
          >
            {loading ? (
              <LoadingOutlined style={{ color: 'white' }} />
            ) : (
              t('confirm-btn')
            )}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Email;
