import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

import {
  Captcha as CaptchaValidation,
  getGuid
} from '@better-bit-fe/base-utils';
// 这里拿到多语言参数locale
export function useCaptcha() {
  const [captcha, setcaptcha] = useState<any>();
  const { locale } = useRouter();
  function showCaptcha() {
    return new Promise((resolve) => {
      captcha?.show(resolve);
    });
  }
  useEffect(() => {
    setcaptcha(
      new CaptchaValidation({
        lang: locale || 'en-US',
        guid: getGuid()
      })
    );
  }, [locale, getGuid()]);

  return { captcha, showCaptcha };
}
